@font-face {
  font-family: 'gilroyExtraBold';
  src: url('gilroy/extraBold/extraBold.eot');
  src: url('gilroy/extraBold/extraBold.eot?#iefix') format('embedded-opentype'),
    url('gilroy/extraBold/extraBold.woff2') format('woff2'),
    url('gilroy/extraBold/extraBold.woff') format('woff'),
    url('gilroy/extraBold/extraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'gilroyMedium';
  src: url('gilroy/medium/medium.eot');
  src: url('gilroy/medium/medium.eot?#iefix') format('embedded-opentype'),
    url('gilroy/medium/medium.woff2') format('woff2'),
    url('gilroy/medium/medium.woff') format('woff'),
    url('gilroy/medium/medium.ttf') format('truetype');
}

@font-face {
  font-family: 'gilroyBold';
  src: url('gilroy/bold/bold.eot');
  src: url('gilroy/bold/bold.eot?#iefix') format('embedded-opentype'),
    url('gilroy/bold/bold.woff2') format('woff2'),
    url('gilroy/bold/bold.woff') format('woff'),
    url('gilroy/bold/bold.ttf') format('truetype');
}
